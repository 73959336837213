import styled from 'styled-components';

/**
 * @todo css 방식으로 리팩토링
 */

export type StackProps = {
  $position?: 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';

  $zIndex?: string;

  $top?: string;
  $left?: string;
  $right?: string;
  $bottom?: string;

  $flexDirection?: 'column' | 'row';
  $alignItems?: string;
  $justifyContent?: string;

  $minWidth?: string;
  $minHeight?: string;
  $width?: string;
  $height?: string;
  $maxWidth?: string;
  $maxHeight?: string;

  $flex?: number;
  $flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial';

  $border?: string;
  $borderRadius?: string;
  $gap?: string;

  $padding?: string;
  $paddingBottom?: string;
  $paddingTop?: string;
  $paddingLeft?: string;
  $paddingRight?: string;

  $margin?: string;
  $marginBottom?: string;
  $marginTop?: string;
  $marginLeft?: string;
  $marginRight?: string;

  $bgColor?: string;
  $fgColor?: string;

  $overflowX?: string;
  $overflowY?: string;
  $transform?: string;
};

export const Stack = styled.div<StackProps>`
${({ $position }) => $position && `position: ${$position};`}

  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'column'};
  align-items: ${({ $alignItems = 'flex-start' }) => $alignItems};
  justify-content: ${({ $justifyContent = 'flex-start' }) => $justifyContent};

  ${({ $zIndex }) => $zIndex && `z-index: ${$zIndex};`}

  ${({ $top }) => $top && `top: ${$top};`}
  ${({ $bottom }) => $bottom && `bottom: ${$bottom};`}
  ${({ $left }) => $left && `left: ${$left};`}
  ${({ $right }) => $right && `right: ${$right};`}

  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight};`}

  ${({ $flex }) => $flex && `flex: ${$flex};`}
  ${({ $flexWrap }) => $flexWrap && `flex-wrap: ${$flexWrap};`}

  ${({ $border }) => $border && `border: ${$border};`}
  ${({ $borderRadius }) => $borderRadius && `border-radius: ${$borderRadius};`}
  ${({ $gap }) => $gap && `gap: ${$gap};`}
  
  ${({ $padding }) => $padding && `padding: ${$padding};`}
  ${({ $paddingLeft }) => $paddingLeft && `padding-left: ${$paddingLeft};`}
  ${({ $paddingRight }) => $paddingRight && `padding-right: ${$paddingRight};`}
  ${({ $paddingTop }) => $paddingTop && `padding-top: ${$paddingTop};`}
  ${({ $paddingBottom }) => $paddingBottom && `padding-bottom: ${$paddingBottom};`}
  
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  ${({ $marginLeft }) => $marginLeft && `margin-left: ${$marginLeft};`}
  ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight};`}
  ${({ $marginTop }) => $marginTop && `margin-top: ${$marginTop};`}
  ${({ $marginBottom }) => $marginBottom && `margin-bottom: ${$marginBottom};`}

  ${({ $bgColor }) => $bgColor && `background: ${$bgColor};`}
  ${({ $fgColor }) => $fgColor && `color: ${$fgColor};`}

  ${({ $overflowX }) => $overflowX && `overflow-x: ${$overflowX};`}
  ${({ $overflowY }) => $overflowY && `overflow-y: ${$overflowY};`}

  ${({ $transform }) => $transform && `transform: ${$transform};`}
`;

export const VStack = styled(Stack)`
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'column'};
`;

export const HStack = styled(Stack)`
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
`;

export const Box = styled(Stack)``;
