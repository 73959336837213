import { ReactNode, useState } from 'react';
import styled from 'styled-components';

type TextPropsType = {
  children: string | ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span' | 'strong';
  color?: string;
  bg?: string;
  fontFamily?: string;
  fontWeight?: number;
  truncated?: boolean;
  fontSize?: string;
  onClick?: () => void;
  maxLength?: number;
  underline?: boolean;
  lineThrough?: boolean;
  $textAlign?: string;
  $lineHeight?: string;
};

const StyledText = styled.span<{
  bg?: string;
  color?: string;
  truncated?: boolean;
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: string;
  $underline?: boolean;
  $lineThrough?: boolean;
  $textAlign?: string;
  $lineHeight?: string;
}>`
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.bg && `background: ${props.bg};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.fontSize && `font-size: ${props.fontSize};`}
  ${(props) => props.fontFamily && `font-family: ${props.fontFamily};`}
  ${(props) => props.$underline && 'text-decoration: underline;'}
  ${(props) => props.$lineThrough && 'text-decoration: line-through;'}
  ${(props) => props.$textAlign && `text-align: ${props.$textAlign};`}
  ${(props) => props.$lineHeight && `line-height: ${props.$lineHeight};`}
  white-space: pre-wrap;
`;

export default function Text(props: TextPropsType) {
  const {
    children,
    as = 'span',
    truncated,
    onClick,
    maxLength,
    underline,
    lineThrough: stroke,
    ...styles
  } = props;

  const [isTruncated, setIsTruncated] = useState(truncated);
  const maxChar = maxLength || 18;

  return (
    <StyledText
      as={as}
      {...styles}
      $underline={underline}
      $lineThrough={stroke}
      onClick={() => {
        onClick?.();
        truncated && setIsTruncated(!isTruncated);
      }}
    >
      {typeof children === 'string' && isTruncated && children.length > maxChar
        ? `${children.substring(0, maxChar)}...`
        : children}
    </StyledText>
  );
}
