import { makeStyledIcon } from './makeStyledIcon';

const LeftArrow = (() =>
  makeStyledIcon(({ color, ...restProps }) => (
    <svg
      {...restProps}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.375 1.25012L1.625 8.00012L8.375 14.7501"
        stroke={color}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )))();

export default LeftArrow;
