import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';
import { ko } from './ko';

i18next.use(initReactI18next).init({
  lng: 'ko',
  fallbackLng: 'ko',
  resources: {
    en,
    ko,
  },
});
