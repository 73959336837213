import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { bookActions, selectBook } from 'store/slices/book';
import { useAppDispatch, useAppSelector } from 'store/useAppStore';
import { AUTH_TTL_IN_SEC, SOFT_AUTH_SEC } from 'utils/const';
import { getTimeDiffInSec } from 'utils/dateUtil';

/**
 * 예약을 위한 절차를 관리하기 위한 훅입니다.
 * 단계를 건너뛰거나, 올바르지 않게 접근하는 것을 막습니다.
 * @returns 
 */
export const useStepAuthenticateManager = () => {
    const booking = useAppSelector(state => selectBook(state));
    const dispatch = useAppDispatch();

    const { startDate, endDate, selectedSeats, payType } = booking; // step0
    const { authenticatedDate, phoneNumber } = booking;             // step1
    const { payed, payedDate } = booking;                           // step2

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const endStep0 = !!(startDate && endDate && selectedSeats && selectedSeats?.length > 0 || payType === 'ADDITIONAL_PAY');
    const endStep1 = !!(phoneNumber && authenticatedDate && getTimeDiffInSec(authenticatedDate, new Date()) < AUTH_TTL_IN_SEC - SOFT_AUTH_SEC)
    const endStep2 = !!(payed && payedDate);

    const step0Paths = ['/', '/book'];
    const step1Paths = [...step0Paths, '/auth'];
    const step2Paths = [...step1Paths, '/bread', '/my', '/pay'];

    useEffect(() => {
        // 결제가 완료되었을 때는 무조건 허용
        if (endStep2 === true) {
            return;
        }

        // my인데 인증이 되지 않은 경우
        if (pathname === '/my' && endStep1 === false) {
            dispatch(bookActions.setPayInfo({ payType: 'ADDITIONAL_PAY' }));
            navigate(step1Paths[step1Paths.length - 1]);
            return;
        }

        // bread인데 인증이 되지 않은 경우
        if (pathname === '/bread' && endStep1 === false) {
            dispatch(bookActions.setPayInfo({ payType: 'BREAD' }));
            navigate(step1Paths[step1Paths.length - 1]);
            return;
        }

        // bread면서 인증이 된 경우. 좌석 선택은 될 필요가 없기 때문에, step1이 참이면 바로 허용해준다.
        if (pathname === '/bread' && endStep1 === true) {
            dispatch(bookActions.setPayInfo({ payType: 'BREAD' }));
            navigate(pathname);
            return;
        }

        // 좌석이 선택되지 않은 경우
        if (endStep0 === false && step0Paths.includes(pathname) === false) {
            navigate(step0Paths[step0Paths.length - 1]);
            return;
        }

        // 인증이 되지 않은 경우
        if (endStep1 === false && step1Paths.includes(pathname) === false) {
            navigate(step1Paths[step1Paths.length - 1]);
            return;
        }

        // 결제가 되지 않은 경우
        if (endStep2 === false && step2Paths.includes(pathname) === false) {
            navigate(step2Paths[step2Paths.length - 1]);
            return;
        }

    }, [location])

    return { authenticated: endStep1 }
}