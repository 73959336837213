export const formatDate = (dateObject?: Date) => {
  const options: any = {
    year: '2-digit',
    month: '2-digit',
    day: 'numeric',
    hour: 'numeric',
  };

  if (!dateObject) {
    return '';
  }
  return new Intl.DateTimeFormat('ko-KR', options).format(dateObject);
};

export const formatSimpleDate = (dateObject?: Date) => {
  const options: any = {
    month: '2-digit',
    day: '2-digit',
  };

  if (!dateObject) {
    return '';
  }
  return new Intl.DateTimeFormat('ko-KR', options).format(dateObject);
};
