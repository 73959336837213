import media from './media-query';

export const lightTheme = {
  value: 'light',
  media,
  color: {
    mainBgColor: '#FAF4ED',

    primary1000: '#B85821',
    primary900: '#C67C4E',
    primary800: '#CD8C64',
    primary700: '#D49D7A',
    primary600: '#DBAD90',
    primary500: '#E3BEA7',
    primary400: '#EACEBD',
    primary300: '#F1DED3',
    primary200: '#F8EFE9',
    primary100: '#FFFFFF',

    secondary1000: '#647B64',
    secondary900: '#66AC66',
    secondary800: '#79B679',
    secondary700: '#8CC18C',
    secondary600: '#9FCB9F',
    secondary500: '#B3D6B3',
    secondary400: '#C6E0C6',
    secondary300: '#D9EAD9',
    secondary200: '#ECF5EC',
    secondary100: '#FFFFFF',
    secondaryGradient: 'linear-gradient(0deg, #66AC66 0%, #647B64 100%)',

    grey900: '#000000',
    grey800: '#202020',
    grey700: '#404040',
    grey600: '#606060',
    grey500: '#808080',
    grey400: '#9F9F9F',
    grey300: '#BFBFBF',
    grey200: '#DFDFDF',
    grey100: '#FFFFFF',

    focus100: '#F3B562',
    focus200: '#EA8848',
    focus300: '#711DB0',
  },
  fontFamily: {
    regular: 'Noto Sans',
    accent: 'Jua',
  },
};

export type Color = keyof typeof lightTheme.color;
export type FontFamily = keyof typeof lightTheme.fontFamily;
