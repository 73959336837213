import { makeStyledIcon } from './makeStyledIcon';

const PlusIcon = (() =>
  makeStyledIcon(({ color, ...restProps }) => (
    <svg
      {...restProps}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 7.99805H8.5V13.998H6.5V7.99805H0.5V5.99805H6.5V-0.00195312H8.5V5.99805H14.5V7.99805Z"
        fill={color}
      />
    </svg>
  )))();

export default PlusIcon;
