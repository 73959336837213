import Button from 'components/Button';
import Spacing from 'components/Spacing';
import { HStack, VStack } from 'components/Stack';
import Text from 'components/Text';
import Header from 'container/common/Header';
import { useTheme } from 'styled-components';

import { useStepAuthenticateManager } from 'hooks/useStepManager';

import BreadImage from 'assets/images/bread.webp';
import FreshBread from 'assets/images/fresh-breads.webp';
import IconButton from 'components/IconButton';
import MyImage from 'components/MyImage';
import MinusIcon from 'components/icons/MinusIcon';
import PlusIcon from 'components/icons/PlusIcon';
import { LanguagePicker } from 'container/common/LanguagePicker';
import { PayTypePriceMapper } from 'features/pay/const';
import { PAY_TYPE } from 'features/pay/type';
import { BootPayErrorType, useBootPayForBread } from 'hooks/useBootPayForBread';
import { useToast } from 'hooks/useToast';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { calcProperDate } from 'utils/calcProperDate';
import { getDate00 } from 'utils/dateUtil';

/**
 * 빵 구매 페이지
 * @returns 
 */
const Bread = () => {
    const { color } = useTheme();
    const navigate = useNavigate();
    const { notifyAlert, notifyBootpayError } = useToast();
    const { t } = useTranslation();
    const { authenticated } = useStepAuthenticateManager();
    const breadPayType: PAY_TYPE = 'BREAD';
    const perPrice = PayTypePriceMapper[breadPayType];
    const [amount, setAmount] = useState(1);
    const price = amount * perPrice;

    const { pay } = useBootPayForBread();

    /** 구매수량 변경 */
    const handleChangeAmount = (amount: number) => {
        if (amount < 1) {
            return;
        }

        if (amount > 7) {
            return;
        }

        setAmount(amount);
    }

    /** 빵 구매가능시간 */
    const isAvailableHour = () => {
        const hour = new Date().getHours();
        return 0 <= hour && hour <= 7;
    }

    /** 추가결제 */
    const doPay = useCallback(async () => {
        if (price <= 0) {
            notifyAlert(t('한개이상구매'));
            return;
        }

        if (!isAvailableHour()) {
            notifyAlert(t('빵구매가능시간아님'));
            return;
        }

        const { minDate, maxDate } = calcProperDate();
        const targetDate = getDate00(minDate);

        try {
            await pay(amount, price, targetDate);
        } catch (error) {
            const bootPayError = error as BootPayErrorType;
            notifyBootpayError(bootPayError);
        }
    }, [price, breadPayType]);

    return (
        <VStack $width='100%' $minHeight='calc(var(--vh, 1vh) * 100)' $bgColor={color.mainBgColor}>
            <Header title={t('빵구매타이틀')} bgColor={color.primary900} fgColor={color.grey100} isCenter isBack
                onClickBack={() => { authenticated ? navigate('/book', { replace: true }) : navigate(-1) }}
                right={<LanguagePicker color='white' />} />

            <VStack $width='100%' $padding='1rem 1rem' $marginLeft='auto' $marginRight='auto' $alignItems='center' $gap='0.5rem'>
                <Text $textAlign='center' fontWeight={600} fontSize='1.1rem'>{t('빵구매설명')} <br /> <s>4,900원</s> {'-> 2,000원'} </Text>
                <MyImage src={FreshBread} height={'280px'} alt='fresh bread' />
                <HStack>
                    {[...Array(amount).keys()].map((i: number) => <MyImage key={i} width={35} src={BreadImage} alt='bread' />)}
                </HStack>
                <HStack $width='90%' $gap='1rem' $alignItems='center' $justifyContent='center' $flexWrap='wrap'>
                    <IconButton icon={MinusIcon} size={20} onClick={() => handleChangeAmount(amount - 1)} color={color.grey900} />
                    <Text fontSize='1.5rem'>{amount}개</Text>
                    <IconButton icon={PlusIcon} size={20} onClick={() => handleChangeAmount(amount + 1)} color={color.grey900} />
                </HStack>
                <Text fontWeight={500} fontSize='1rem'>{t('빵구매안내')}</Text>
                <Spacing margin='2rem' />
            </VStack>

            {/** CTAButton */}
            <HStack $position='fixed' $bottom='0' $width='100%' $maxWidth='var(--app-max-width)'>
                <HStack $padding='1rem 1.5rem' $width='100%' $alignItems='center' $justifyContent='center'>
                    <Button $flexDirection='row' $justifyContent='center' $alignItems='center'
                        $bgColor={color.primary900} $fgColor={color.grey100}
                        $borderRadius='12px' $width='100%'
                        $height='50px'
                        onClick={doPay}
                    >
                        <Text fontSize='1.1rem' fontWeight={700}>
                            {t('원결제하기', { price: price.toLocaleString() })}
                        </Text>
                    </Button>
                </HStack>
            </HStack>
        </VStack>
    );
};

export default Bread;
