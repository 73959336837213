import { memo } from 'react';

type ImageProps = {
  src: string | any;
  alt: string;
  maxWidth?: string | number;
  width?: string | number;
  height?: string | number;
  style?: any;
  $objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  $objectPosition?: string;
};

const MyImage = ({ ...props }: ImageProps) => {
  const {
    src,
    alt,
    style,
    width = 'auto',
    height = 'auto',
    maxWidth = '100%',
    $objectFit,
    $objectPosition,
    ...others
  } = props;
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={{
        maxWidth: maxWidth,
        objectFit: $objectFit,
        objectPosition: $objectPosition,
        ...style,
      }}
      {...others}
    />
  );
};

export default memo(MyImage);
