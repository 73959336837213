import { memo } from 'react';
import styled from 'styled-components';

const StyledSpacing = styled.div<{ $margin?: string }>`
  margin: ${(props) => props.$margin || '1rem'};
  overflow: auto;
`;

const Spacing = ({ margin }: { margin?: string }) => {
  return <StyledSpacing $margin={margin} />;
};

export default memo(Spacing);
