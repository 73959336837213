import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type LanguagePickerPropTypes = {
    color?: string
}

export const LanguagePicker = ({ color = '#000' }: LanguagePickerPropTypes) => {
    const { i18n } = useTranslation()
    const isEn = i18n.language === 'en';

    const changeLanguageHandler = (lang: 'ko' | 'en') => {
        i18n.changeLanguage(lang);
    }

    return isEn ?
        <Button onClick={() => changeLanguageHandler('ko')}>
            <Logo $color={color}>한</Logo>
        </Button >
        :
        <Button onClick={() => changeLanguageHandler('en')}>
            <Logo $color={color}>En</Logo>
        </Button>
}

const Logo = styled.span<{ $color?: string }>`
  font-size: 1.7rem;
  font-family: ${(props) => props.theme.fontFamily.accent};
  color: ${props => props.$color || props.theme.color.grey700};
`;