import IconButton from 'components/IconButton';
import { Box, HStack, VStack } from 'components/Stack';
import LeftArrow from 'components/icons/LeftArrow';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled, { useTheme } from 'styled-components';

type HeaderPropsType = {
  title?: string;
  bgColor?: string;
  fgColor?: string;
  isBack?: boolean;
  onClickBack?: () => void;
  isCenter?: boolean;
  right?: ReactNode;
}

const Header = ({ title, bgColor, fgColor, isBack = false, isCenter = false, onClickBack, right }: HeaderPropsType) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  onClickBack = onClickBack ? onClickBack : isBack ? (() => navigate(-1)) : undefined;

  return (
    <VStack $width='100%'>
      <HStack $width='100%' $bgColor={bgColor} $alignItems='center' as={'header'}>
        {isBack && <Box $position='absolute' $left='5px'>
          <IconButton onClick={onClickBack} size={16} icon={LeftArrow} color={fgColor || theme.color.grey700} />
        </Box>}

        <HStack $padding='1rem 1rem' $justifyContent={isCenter ? 'center' : 'flex-start'} $width='100%'>
          <Logo $color={fgColor}>{title || t('카공24')}</Logo>
        </HStack>

        {right && <HStack $position='absolute' $right='10px'>
          {right}
        </HStack>}
      </HStack>
    </VStack>
  );
};

const Logo = styled.h1<{ $color?: string }>`
  font-family: ${(props) => props.theme.fontFamily.accent};
  color: ${props => props.$color || props.theme.color.grey700};
`;

export default memo(Header);
