import { Box, StackProps } from 'components/Stack';
import { ReactNode, memo } from 'react';
import styled from 'styled-components';

type StyledButtonProps = StackProps;

const StyledButton = styled(Box)``;

type ButtonProps = {
  onClick?: () => void;
  children?: ReactNode;
} & StyledButtonProps;

const Button = ({ children, onClick, ...props }: ButtonProps) => {
  return (
    <StyledButton as={'button'} onClick={() => onClick?.()} {...props}>
      {children}
    </StyledButton>
  );
};

export default memo(Button);
