import { useEffect } from 'react';

/**
 * 화면 크기가 바뀌는 경우 적절한 높이와 너비를 지정하기 위한 훅입니다.
 */
export const useResizeHandler = () => {
  const setScreenSize = () => {
    // vh 관련
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // window width 관련
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const maxWidth = Math.min(768, windowWidth);
    document.documentElement.style.setProperty(
      '--app-max-width',
      `${maxWidth}px`,
    );
  };

  useEffect(() => {
    setScreenSize();
    window.addEventListener('resize', setScreenSize);

    return () => {
      window.removeEventListener('resize', setScreenSize);
    };
  }, []);
};
