import { publicAxios } from 'features/auth/auth.config';
import type {
  BreadOrderBeforeConfirmRequest,
  BreadOrderBeforeConfirmResponse,
  BreadPayConfirmRequest,
  OrderBeforeConfirmRequest,
  OrderBeforeConfirmResponse,
  PayConfirmRequest,
} from './type';

// 결제확인요청 이전에 서버에 확인을 받습니다.
export const orderBeforeConfirm = async (body: OrderBeforeConfirmRequest) => {
  const { token, ...otherBody } = body;
  const { data } = await publicAxios.post<IResponse<OrderBeforeConfirmResponse>>('/reservation/order', otherBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { result } = data;
  return result;
};

// 서버에 결제확인요청을 보냅니다.
export const confirmPay = async (body: PayConfirmRequest) => {
  const { token, ...otherBody } = body;
  const { data } = await publicAxios.post('/reservation/confirm', otherBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// 빵 구매 결제확인요청 이전에 서버에 확인을 받습니다.
export const orderBreadBeforeConfirm = async (body: BreadOrderBeforeConfirmRequest) => {
  const { count, token } = body;
  const { data } = await publicAxios.post<IResponse<BreadOrderBeforeConfirmResponse>>(
    '/bread/order',
    { count },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const { result } = data;
  return result;
};

// 빵 구매 서버로 결제확인요청을 보냅니다.
export const confirmBreadPay = async (body: BreadPayConfirmRequest) => {
  const { token, ...otherBody } = body;
  const { data } = await publicAxios.post('/bread/confirm', otherBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
