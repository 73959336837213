import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { bookActions, selectBook } from 'store/slices/book';
import { useAppDispatch, useAppSelector } from 'store/useAppStore';
import { AVAILABLE_RESULT_CHECK_TIME_IN_SECS } from 'utils/const';
import { getTimeDiffInSec } from 'utils/dateUtil';
import { delay } from 'utils/delay';

/**
 * 결과화면 및 리덕스 스토어의 TTL을 정하기 위한 훅입니다.
 */
export const useStoreLifeManager = () => {
    const booking = useAppSelector(state => selectBook(state));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { payed, payedDate } = booking;

    useEffect(() => {
        /**
         * 결제가 되었고, 결제결과를 확인할 수 있는 최대 시간이 지나면 리덕스 스토어를 초기화합니다.
         */
        const clear = async () => {
            if (payed && payedDate) {
                const durationTime = getTimeDiffInSec(payedDate, new Date());
                if (AVAILABLE_RESULT_CHECK_TIME_IN_SECS < durationTime) {
                    dispatch(bookActions.clearExceptAuth());
                    navigate('/')
                } else {
                    await delay(Math.abs(AVAILABLE_RESULT_CHECK_TIME_IN_SECS - durationTime) * 1000);
                    dispatch(bookActions.clearExceptAuth());
                    navigate('/')
                }
            }
        }
        clear();
    }, [payed, pathname])
}