import MyImage from 'components/MyImage';
import { Box } from 'components/Stack';
import Text from 'components/Text';
import { PAY_TYPE } from 'features/pay/type';
import { memo } from 'react';

import BlankChairImage from 'assets/images/blank-chair.webp';
import ChairImage from 'assets/images/chair.webp';
import { useTheme } from 'styled-components';

type SeatProps = {
    seatNumber: number | string;
    type: PAY_TYPE;
    selected?: boolean;
}

const Seat = ({ seatNumber, type, selected = false }: SeatProps) => {
    const { color } = useTheme();

    return <Box $alignItems='center' $justifyContent='center' $padding='0.5rem' $borderRadius='6px'
        $border={selected ? `3px solid ${color.secondary900}` : undefined} className='pointer' $gap='0.5rem'>
        <MyImage width={50} height={50} src={type === 'USE_SPACE_WITH_CAFE' ? BlankChairImage : ChairImage} alt='chair' />
        <Text $textAlign='center'>{`${seatNumber}`}</Text>
    </Box>

}

export default memo(Seat);