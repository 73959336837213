import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { PayTypePriceMapper } from 'features/pay/const';
import { PAY_TYPE } from 'features/pay/type';
import { getDate00, getTimeDiffInSec } from 'utils/dateUtil';
import { AUTH_TTL_IN_SEC, SOFT_AUTH_SEC } from 'utils/const';
import { calcProperDate } from 'utils/calcProperDate';

export interface BookingState {
  startDate?: string;
  endDate?: string;
  selectedSeats?: number[];

  phoneNumber?: string;
  authenticatedDate?: string;
  token?: string;

  payType: PAY_TYPE;
  totalPrice: number;
  payed?: boolean;
  payedDate?: string;
}

export const calcPrice = (payType: PAY_TYPE, numberOfSeats: number) => {
  const perPrice = PayTypePriceMapper[payType] || 0;
  const totalPrice = perPrice * numberOfSeats;
  return totalPrice;
};

const initialState: BookingState = {
  startDate: undefined,
  endDate: undefined,
  selectedSeats: undefined,

  phoneNumber: undefined,
  authenticatedDate: undefined,
  token: undefined,

  payType: 'USE_SPACE_ONLY',
  totalPrice: 0,
  payed: false,
  payedDate: undefined,
};

const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    // 예약정보를 등록합니다.
    setBookingInfo: (state, action: PayloadAction<{ startDate: Date; seatNumber: number[] }>) => {
      const { startDate, seatNumber } = action.payload;
      startDate.setHours(1);

      const endDate = new Date(startDate);
      const endHour = 7;
      endDate.setHours(endHour);

      state.startDate = startDate.toJSON();
      state.endDate = endDate.toJSON();
      state.selectedSeats = seatNumber;

      state.totalPrice = calcPrice(state.payType, state.selectedSeats?.length || 0);
    },

    // 인증정보를 등록합니다.
    setAuthenticated: (
      state,
      action: PayloadAction<{ phoneNumber: string; token: string; authenticatedDate?: Date }>,
    ) => {
      const { phoneNumber, token, authenticatedDate = new Date() } = action.payload;
      state.phoneNumber = phoneNumber;
      state.token = token;
      state.authenticatedDate = authenticatedDate.toJSON();
    },

    // 결제전 정보를 등록합니다.
    setPayInfo: (state, action: PayloadAction<{ payType: PAY_TYPE }>) => {
      const { payType } = action.payload;

      state.payType = payType;
      state.totalPrice = calcPrice(payType, state.selectedSeats?.length || 0);
    },

    // 결제 후 정보를 등록합니다.
    setPayed: (state, action: PayloadAction<{ payed: boolean; payedDate?: Date }>) => {
      const { payed, payedDate = new Date() } = action.payload;

      state.payed = payed;
      state.payedDate = payedDate.toJSON();
    },

    // 인증토큰을 제외하고 초기화합니다.
    clearExceptAuth: (state) => {
      const { phoneNumber, authenticatedDate, token } = state;
      const clearExceptAuth = { ...initialState, phoneNumber, authenticatedDate, token };
      Object.assign(state, clearExceptAuth);
    },

    // 초기화
    clear: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const bookActions = bookSlice.actions;

const isAuthenticated = (phoneNumber: string | undefined, authenticatedDate: string | undefined) => {
  const authenticated = !!(
    phoneNumber &&
    authenticatedDate &&
    getTimeDiffInSec(new Date(authenticatedDate), new Date()) < AUTH_TTL_IN_SEC - SOFT_AUTH_SEC
  );
  return authenticated;
};

const ensureTime = (date: string | undefined) => {
  const { minDate } = calcProperDate();

  if (!date) {
    return minDate;
  }

  const d = new Date(date);
  return getDate00(d).getTime() >= getDate00(minDate).getTime() ? d : minDate;
};

export const selectBook = createSelector(
  (state: RootState) => state,
  ({ book }) => {
    const { startDate, endDate, selectedSeats } = book;
    const { phoneNumber, authenticatedDate } = book;
    const { payed, payedDate, payType, totalPrice } = book;

    return {
      startDate: ensureTime(startDate),
      endDate: ensureTime(endDate),
      selectedSeats,
      phoneNumber,
      authenticatedDate: authenticatedDate ? new Date(authenticatedDate) : undefined,
      authenticated: isAuthenticated(phoneNumber, authenticatedDate),
      payed,
      payedDate: payedDate ? new Date(payedDate) : undefined,
      payType,
      totalPrice,
    };
  },
);

export const selectBookingInfo = createSelector(
  (state: RootState) => state,
  ({ book }) => {
    const { startDate, endDate, selectedSeats, totalPrice, payType } = book;

    return {
      startDate: ensureTime(startDate),
      endDate: ensureTime(endDate),
      selectedSeats,
      payType,
      totalPrice,
    };
  },
);

export const selectBookingAuthenticated = createSelector(
  (state: RootState) => state,
  ({ book }) => {
    const { phoneNumber, authenticatedDate, token } = book;

    return {
      phoneNumber,
      token,
      authenticatedDate: authenticatedDate && new Date(authenticatedDate),
      authenticated: isAuthenticated(phoneNumber, authenticatedDate),
    };
  },
);

export default bookSlice.reducer;
