import Button from 'components/Button';
import Spacing from 'components/Spacing';
import { Box, HStack, VStack } from 'components/Stack';
import Text from 'components/Text';
import Header from 'container/common/Header';
import { useToast } from 'hooks/useToast';
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { useStepAuthenticateManager } from 'hooks/useStepManager';

import LoadingLottie from 'assets/images/lotties/loading-section.json';
import Seat from 'components/Seat';
import { LanguagePicker } from 'container/common/LanguagePicker';
import { useMy } from 'features/my/my.hook';
import { PayTypePriceMapper } from 'features/pay/const';
import { PAY_TYPE } from 'features/pay/type';
import { BootPayErrorType, useBootPay } from 'hooks/useBootPay';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { formatSimpleDate } from 'screens/BookingResult/dateFormatter';
import { bookActions, selectBookingAuthenticated } from 'store/slices/book';
import { useAppDispatch, useAppSelector } from 'store/useAppStore';
import { PLACE_NAME } from 'utils/const';
import { getDate00 } from 'utils/dateUtil';
import { fromNow } from 'utils/fromNow';
import BreadOrderView from 'container/my/BreadOrderView';

/**
 * 예약정보 불러오기 & 추가결제 필요시 결제창
 * @returns 
 */
const My = () => {
    const { color } = useTheme();
    const navigate = useNavigate();
    const { notifyAlert, notifyBootpayError } = useToast();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { phoneNumber } = useAppSelector(state => selectBookingAuthenticated(state));
    const { authenticated } = useStepAuthenticateManager();
    const [selectedSeat, setSelectedSeat] = useState<{ [key: string]: { date: Date, seatId: number } }>({});
    const additionalPayType: PAY_TYPE = 'ADDITIONAL_PAY';
    const price = Object.keys(selectedSeat).length * PayTypePriceMapper[additionalPayType];

    const { data, isLoading, error } = useMy();
    const { pay } = useBootPay();

    /** 좌석 선택 */
    const handleOnClickSeat = ({ key: seatKey, payType, seatId, date }: { key: string, payType: PAY_TYPE, seatId: number, date: Date }) => {
        // 추가결제 할 수 없는 좌석에 대해서는 선택되지 않도록 한다.
        if (payType !== 'USE_SPACE_WITH_CAFE') {
            return;
        }

        // 다른 날짜끼리의 결제는 지원되지 않음.
        const selectedSeatDates = Object.values(selectedSeat);
        if (selectedSeatDates.length > 0 && selectedSeatDates[0].date.getTime() !== date.getTime()) {
            notifyAlert('선택된 좌석과 동일한 날짜만 선택하여 결제할 수 있습니다.');
            return;
        }

        if (selectedSeat[seatKey]) {
            const { [seatKey]: _, ...others } = selectedSeat;
            setSelectedSeat(others);
            return;
        }
        setSelectedSeat({ ...selectedSeat, [seatKey]: { date, seatId } });
    }

    /** 추가결제 */
    const doPay = useCallback(async () => {
        if (Object.keys(selectedSeat).length <= 0) {
            notifyAlert('좌석이 선택되지 않았습니다.');
            return;
        }

        const targetDateString = Object.values(selectedSeat)[0].date;
        const targetDate = getDate00(new Date(targetDateString));
        const targetSeats = Object.values(selectedSeat).map(({ seatId }) => seatId);

        dispatch(bookActions.setPayInfo({ payType: additionalPayType }));
        dispatch(bookActions.setBookingInfo({ startDate: targetDate, seatNumber: [...targetSeats] }))

        try {
            await pay(price, PLACE_NAME, targetSeats, additionalPayType, targetDate);
        } catch (error) {
            const bootPayError = error as BootPayErrorType;
            notifyBootpayError(bootPayError);
        }
    }, [price, additionalPayType]);

    return (
        <VStack $width='100%' $minHeight='calc(var(--vh, 1vh) * 100)' $bgColor={color.mainBgColor}>
            <Header title={t('마이페이지타이틀')} bgColor={color.primary900} fgColor={color.grey100} isCenter isBack
                onClickBack={() => { authenticated ? navigate('/book', { replace: true }) : navigate(-1) }}
                right={<LanguagePicker color='white' />} />

            <VStack $width='100%' $padding='1rem 1rem' $marginLeft='auto' $marginRight='auto'>
                <Text fontWeight={700}>{t('안녕하세요')} 010-{phoneNumber?.slice(3, 7)}-xxxx님</Text>
                <Text fontWeight={700} fontSize='1.1rem'>{t('카공24구매후멘트')}</Text>
                <Spacing margin='0.5rem' />
                <VStack $width='100%'>
                    <BreadOrderView />
                </VStack>
                <Spacing margin='0.5rem' />
                {/* <Text fontWeight={500}>{t('추가결제안내')}</Text>
                <Spacing margin='0.5rem' /> */}
                {/* <HStack>
                    <Seat seatNumber={t('카페마감이후이용권')} type='USE_SPACE_ONLY' />
                    <Seat seatNumber={t('카페마감이전이용권')} type='USE_SPACE_WITH_CAFE' />
                </HStack> */}
                <VStack $width='100%' $gap='1rem' $alignItems='center' $justifyContent='center'>
                    {data ? data?.flatMap((book) => {
                        const dateString = book[0];
                        return <VStack key={dateString} $width='100%' $padding='0.5rem' $bgColor={color.grey100}>
                            <Text>{`${fromNow(dateString)} (${formatSimpleDate(new Date(dateString))})`}</Text>
                            <Spacing margin='0.3rem' />
                            <HStack $width='100%' $gap='1rem' $flexWrap='wrap'>
                                {
                                    book[1].map(({ date, seatId, payType }) => {
                                        const key = date.toJSON() + seatId;
                                        return <Box key={key} onClick={() => handleOnClickSeat({ key, payType, seatId, date: new Date(date) })}>
                                            <Seat type={payType} seatNumber={`${seatId}번`} selected={!!selectedSeat[key]} />
                                        </Box>
                                    })
                                }
                            </HStack>
                        </VStack>
                    })
                        :
                        <Lottie animationData={LoadingLottie} />
                    }

                </VStack>

                <Spacing margin='2rem' />

            </VStack>

            {/** CTAButton */}
            {/* <HStack $position='fixed' $bottom='0' $width='100%' $maxWidth='var(--app-max-width)'>
                <HStack $padding='1rem 1.5rem' $width='100%' $alignItems='center' $justifyContent='center'>
                    <Button $flexDirection='row' $justifyContent='center' $alignItems='center'
                        $bgColor={color.primary900} $fgColor={color.grey100}
                        $borderRadius='12px' $width='100%'
                        $height='50px'
                        onClick={doPay}
                    >
                        <Text fontSize='1.1rem' fontWeight={700}>
                            {t('원결제하기', { price: price.toLocaleString() })}
                        </Text>
                    </Button>
                </HStack>
            </HStack> */}
        </VStack>
    );
};

export default My;
