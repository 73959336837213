import { publicAxios } from 'features/auth/auth.config';
import { BookStatus } from './book';

/** 내 예약내역 */
export const fetchBookStatus = async (date: Date) => {
  const { data } = await publicAxios.get<IResponse<BookStatus[]>>('/reservation', {
    params: {
      date: date.toJSON(),
    },
  });
  return new Set(data?.result?.map((data) => Number(data.seatId)));
};
