import { useQuery } from '@tanstack/react-query';
import { fetchBookStatus } from './book.api';

export const bookKeys = {
  all: ['book'],
  list: (date: string) => [...bookKeys.all, 'list', { date }],
};

export const useBookStatus = (date: Date) => {
  return useQuery(bookKeys.list(date.toJSON()), () => fetchBookStatus(date), {
    refetchInterval: 30 * 1000,
  });
};
