import { Bootpay } from '@bootpay/client-js'
import { useQueryClient } from '@tanstack/react-query'
import { myKeys } from 'features/my/my.hook'
import { PayType2Dto } from 'features/pay/const'
import { confirmPay, orderBeforeConfirm } from 'features/pay/pay.api'
import { PAY_TYPE } from 'features/pay/type'
import { useNavigate } from 'react-router'
import { bookActions, selectBookingAuthenticated } from 'store/slices/book'
import { useAppDispatch, useAppSelector } from 'store/useAppStore'
import { calcProperDate } from 'utils/calcProperDate'
import { BOOTPAY_APP_ID } from 'utils/const'
import { getDate00 } from 'utils/dateUtil'
import { useToast } from './useToast'

export interface BootPayErrorType {
    error_code: string,
    message: '사용자가 결제창을 닫았습니다.'
    event?: string,
    payload?: any
    pg_error_code?: any
}

export interface ConfirmPayloadType {
    event: string,
    gateway_url: string,
    order_id: string,
    receipt_id: string
}

export const useBootPay = () => {
    const toast = useToast();
    const { phoneNumber, token } = useAppSelector(state => selectBookingAuthenticated(state));
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // https://docs.bootpay.co.kr/?front=web&backend=nodejs#request-payment
    const pay = async (price: number, placeName: string, seatNumbers: number[], payType: PAY_TYPE, targetDate: Date) => {
        if (!token || !phoneNumber) {
            toast.notifyAlert('인증정보가 없습니다.');
            navigate('/auth');
            return;
        }

        const { minDate } = calcProperDate();
        targetDate = minDate.getTime() <= targetDate.getTime() ? targetDate : minDate;

        const itemName = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${placeName} ${seatNumbers.join(',')}번 좌석`;
        try {
            const { orderId } = await orderBeforeConfirm({
                date: getDate00(targetDate).toJSON(),
                seatIds: seatNumbers.map(seat => seat.toString()),
                token,
                payType: PayType2Dto[payType]
            });

            const response: ConfirmPayloadType = await Bootpay.requestPayment({
                'application_id': BOOTPAY_APP_ID,
                'price': price,
                'order_name': itemName,
                'order_id': orderId,
                'pg': '이니시스',
                'items': [
                    {
                        'id': itemName,
                        'name': itemName,
                        'qty': 1,
                        'price': price
                    }
                ],
                'user': {
                    'phone': phoneNumber
                },
                'extra': {
                    'open_type': 'iframe',
                    'card_quota': '0',
                    'escrow': false,
                    'separately_confirmed': true
                },
            })

            switch (response.event) {
                case 'issued':
                    onSuccessHandler();
                    break
                case 'confirm':
                    await confirmPay({
                        receiptId: response.receipt_id,
                        token
                    })
                    onSuccessHandler();
                    break
            }
        } catch (e) {
            console.error('error: ', e)
            const error = e as BootPayErrorType;
            toast.notifyBootpayError(error);
            dispatch(bookActions.setPayed({ payed: false }))
        }
        finally {
            Bootpay.destroy();
        }

    }

    const onSuccessHandler = () => {
        toast.notifySuccess('결제에 성공했습니다.');
        dispatch(bookActions.setPayed({ payed: true }))
        navigate('/result');
        queryClient.invalidateQueries(myKeys.all);
    }

    return { pay };
}