import { Bootpay } from '@bootpay/client-js'
import { useQueryClient } from '@tanstack/react-query'
import { myKeys } from 'features/my/my.hook'
import { confirmBreadPay, orderBreadBeforeConfirm } from 'features/pay/pay.api'
import { useNavigate } from 'react-router'
import { selectBookingAuthenticated } from 'store/slices/book'
import { useAppDispatch, useAppSelector } from 'store/useAppStore'
import { BOOTPAY_APP_ID, PLACE_NAME } from 'utils/const'
import { useToast } from './useToast'

export interface BootPayErrorType {
    error_code: string,
    message: '사용자가 결제창을 닫았습니다.'
    event?: string,
    payload?: any
    pg_error_code?: any
}

export interface ConfirmPayloadType {
    event: string,
    gateway_url: string,
    order_id: string,
    receipt_id: string
}

export const useBootPayForBread = () => {
    const toast = useToast();
    const { phoneNumber, token } = useAppSelector(state => selectBookingAuthenticated(state));
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // https://docs.bootpay.co.kr/?front=web&backend=nodejs#request-payment
    const pay = async (amount: number, price: number, targetDate: Date) => {
        if (!token || !phoneNumber) {
            toast.notifyAlert('인증정보가 없습니다.');
            navigate('/auth');
            return;
        }

        const itemName = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${PLACE_NAME} 빵 ${amount}개`;
        try {
            const { orderId } = await orderBreadBeforeConfirm({ token, count: amount });

            const response: ConfirmPayloadType = await Bootpay.requestPayment({
                'application_id': BOOTPAY_APP_ID,
                'price': price,
                'order_name': itemName,
                'order_id': orderId,
                'pg': '이니시스',
                'items': [
                    {
                        'id': itemName,
                        'name': itemName,
                        'qty': 1,
                        'price': price
                    }
                ],
                'user': {
                    'phone': phoneNumber
                },
                'extra': {
                    'open_type': 'iframe',
                    'card_quota': '0',
                    'escrow': false,
                    'separately_confirmed': true
                },
            })

            switch (response.event) {
                case 'issued':
                    onSuccessHandler();
                    break;
                case 'confirm':
                    await confirmBreadPay({
                        receiptId: response.receipt_id,
                        token
                    })
                    onSuccessHandler();
                    break;
            }
        } catch (e) {
            console.error('error: ', e)
            const error = e as BootPayErrorType;
            toast.notifyBootpayError(error);
        }
        finally {
            Bootpay.destroy();
        }

    }

    const onSuccessHandler = () => {
        toast.notifySuccess('결제에 성공했습니다.');
        navigate('/my');
        queryClient.invalidateQueries(myKeys.all);
    }

    return { pay };
}