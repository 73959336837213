import { ToastOptions } from 'react-hot-toast';

export const BOOTPAY_APP_ID = '600a6b615b2948002151ebdc';
export const PLACE_NAME = '대학동 파머스빈';
export const AUTH_TTL_IN_SEC = 7 * 24 * 60 * 60; // 세션 유지시간: 7일
export const SOFT_AUTH_SEC = 5 * 60; // 예약진행 중간에 로그아웃되는 것을 막기 위한 여유시간
export const AVAILABLE_RESULT_CHECK_TIME_IN_SECS = 2 * 60 + 30;
export const MANAGER_PHONE_NUMBER = '010-7592-2316';

export const SUCCESS_TOAST: ToastOptions = {
  duration: 3400,
  position: 'top-center',

  // Styling
  style: {},
  className: '',

  // Custom Icon
  icon: '✅',

  iconTheme: {
    primary: '#000',
    secondary: '#fff',
  },

  // Aria
  ariaProps: {
    role: 'status',
    'aria-live': 'polite',
  },
};

export const ERROR_TOAST: ToastOptions = {
  duration: 3400,
  position: 'top-center',

  // Styling
  style: {
    backgroundColor: '#E15549',
    color: '#fff',
  },
  className: '',

  // Custom Icon
  icon: '❕',

  iconTheme: {
    primary: '#FFF',
    secondary: 'red',
  },

  // Aria
  ariaProps: {
    role: 'status',
    'aria-live': 'polite',
  },
};

export const ALERT_TOAST: ToastOptions = {
  duration: 3400,
  position: 'top-center',

  // Styling
  style: {
    backgroundColor: 'orange',
    color: '#fff',
  },
  className: '',

  // Custom Icon
  icon: '🟠',

  iconTheme: {
    primary: '#FFF',
    secondary: 'red',
  },

  // Aria
  ariaProps: {
    role: 'status',
    'aria-live': 'polite',
  },
};
