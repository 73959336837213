import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bookKeys } from 'features/book/book.hook';
import { useToast } from 'hooks/useToast';
import { useNavigate } from 'react-router';
import { selectBookingAuthenticated } from 'store/slices/book';
import { useAppSelector } from 'store/useAppStore';
import { changeSeats, fetchMyBooks, fetchMyBreads } from './my.api';

export const myKeys = {
  all: ['my'],
  reservation: () => [...myKeys.all, 'reservation'],
  bread: () => [...myKeys.all, 'bread'],
};

/** 좌석예약내역 */
export const useMyWithoutRedirect = () => {
  const { token } = useAppSelector((state) => selectBookingAuthenticated(state));
  if (!token) {
    return { isLoading: false, data: undefined, error: 'Not Authorized' };
  }

  return useQuery(myKeys.reservation(), () => fetchMyBooks(token));
};

/** 좌석예약내역 */
export const useMy = () => {
  const { token } = useAppSelector((state) => selectBookingAuthenticated(state));
  const navigate = useNavigate();
  if (!token) {
    navigate('/auth');
    return { isLoading: false, data: undefined, error: 'Not Authorized' };
  }

  return useQuery(myKeys.all, () => fetchMyBooks(token));
};

/** 좌석변경 */
export const useChangeSeatMutation = (date: Date) => {
  const queryClient = useQueryClient();
  const { notifyError } = useToast();

  return useMutation(changeSeats, {
    onSuccess: () => {
      queryClient.invalidateQueries(bookKeys.list(date.toJSON()));
      queryClient.invalidateQueries(myKeys.reservation());
    },
    onError: (error) => {
      notifyError(error);
    },
  });
};

/** 빵 구매내역 */
export const useBread = () => {
  const { token } = useAppSelector((state) => selectBookingAuthenticated(state));
  const navigate = useNavigate();
  if (!token) {
    navigate('/auth');
    return { isLoading: false, data: undefined, error: 'Not Authorized' };
  }

  return useQuery(myKeys.bread(), () => fetchMyBreads(token));
};
