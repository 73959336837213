import { publicAxios } from 'features/auth/auth.config';
import { ChangeSeatRequest } from 'features/book/book';
import { MyBookListItemResponse, MyBreadItem } from './my';
import { refineMyData } from './my.util';

// 내 좌석예약 내역
export const fetchMyBooks = async (token: string) => {
  const { data } = await publicAxios.get<IResponse<MyBookListItemResponse[]>>('/my/reservation', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return refineMyData(data);
};

// 내 빵 구매내역
export const fetchMyBreads = async (token: string, date: Date = new Date()) => {
  const { data } = await publicAxios.get<IResponse<MyBreadItem[]>>('/my/bread-order', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      date: date.toJSON(),
    },
  });

  return data.result;
};

// 내 좌석변경
export const changeSeats = async ({ token, reservations }: ChangeSeatRequest) => {
  const { data } = await publicAxios.put(
    '/reservation',
    {
      reservations,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data.result;
};
