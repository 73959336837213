import { ALERT_TOAST, ERROR_TOAST, SUCCESS_TOAST } from 'utils/const';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { BootPayErrorType } from './useBootPay';

export const useToast = () => {
  const notifySuccess = (value = '저장에 성공했습니다.') =>
    toast(value, SUCCESS_TOAST);

  const notifyError = (e?: any) => {
    if (e instanceof AxiosError && e.response?.data.message) {
      const errorMessage = e.response?.data.message;
      toast(`다음과 같은 오류가 발생했습니다.\n${errorMessage}`, ERROR_TOAST);
      return;
    }
    toast(`다음과 같은 오류가 발생했습니다.\n${e}`, ERROR_TOAST);
  };

  const notifyBootpayError = (e?: BootPayErrorType) => {
    toast(`결제에 실패했습니다.\n${e?.message}`, ERROR_TOAST);
  };

  const notifyAlert = (value: string) => toast(value, ALERT_TOAST);

  return { notifySuccess, notifyError, notifyBootpayError, notifyAlert };
};
