import { Dto2PayType } from 'features/pay/const';
import { getDate00 } from 'utils/dateUtil';
import { MyBookItem, MyBookListItemResponse } from './my';

/**
 * {날짜: [예약정보 모음]} 형식으로 매핑
 * @param rawData
 * @returns
 */
export const refineMyData = (rawData: IResponse<MyBookListItemResponse[]>) => {
  // date 객체 포함 및 숫자변환
  const data: MyBookItem[] = rawData.result.map(({ reservationId, date, seatId, receiptId, payType }) => ({
    reservationId: Number(reservationId),
    date: new Date(date),
    seatId: Number(seatId),
    receiptId,
    payType: Dto2PayType[payType],
  }));

  // 이전 예약내역 제거
  const today = getDate00(new Date());
  const filteredData = data.filter((d) => d.date.getTime() >= today.getTime());

  // {날짜: [예약정보 모음]} 형식으로 매핑
  const mapper: { [key: string]: MyBookItem[] } = {};

  for (const book of filteredData) {
    const { date } = book;
    const dateString = date.toJSON();

    if (mapper[dateString]) {
      mapper[dateString].push(book);
      continue;
    }
    mapper[dateString] = [book];
  }

  const sortedData = Object.entries(mapper).sort(([a, _], [b, __]) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  return sortedData;
};
