
import LoadingLottie from 'assets/images/lotties/loading-section.json';
import { HStack, VStack } from 'components/Stack';
import Text from 'components/Text';
import { useBread } from 'features/my/my.hook';
import Lottie from 'lottie-react';
import { memo } from 'react';
import { date2SimpleMonthDate } from 'utils/timeFormat';
import Bread from '../Bread';

const BreadOrderView = () => {
    const { data, isLoading, error } = useBread();

    if (isLoading) {
        return <VStack $width='100%' $alignItems='center' $justifyContent='center'>
            <Lottie animationData={LoadingLottie} />
        </VStack>
    }

    if (error || !data) {
        return <VStack $width='100%' $alignItems='center' $justifyContent='center'>
            <Text>에러가 발생했어요: {error?.toString()}</Text>
        </VStack>
    }

    return <VStack $width='100%'>
        <HStack $gap='0.5rem' $flexWrap='wrap' $width='100%'>
            {
                data.map(({ breadOrderId, createdAt, count, receiptId, phoneNumber }) => {
                    return <Bread key={breadOrderId} innerValue={`x${count}`} value={date2SimpleMonthDate(new Date(createdAt))} />
                })
            }
        </HStack>
    </VStack>
}

export default memo(BreadOrderView);