import { makeStyledIcon } from './makeStyledIcon';

const MinusIcon = (() =>
  makeStyledIcon(({ color, ...restProps }) => (
    <svg
      {...restProps}
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 1.99805H0V-0.00195312H14V1.99805Z" fill={color} />
    </svg>
  )))();

export default MinusIcon;
