import BreadImage from 'assets/images/bread.webp';
import MyImage from 'components/MyImage';
import { Box } from 'components/Stack';
import Text from 'components/Text';
import { memo } from 'react';
import { useTheme } from 'styled-components';

type BreadPropType = {
    value?: string;
    innerValue?: string;
}

const Bread = ({ innerValue, value }: BreadPropType) => {
    const { fontFamily, color } = useTheme()
    return <Box $alignItems='center' $justifyContent='center' $position='relative'>
        <MyImage width={'50px'} src={BreadImage} alt="bread" />
        <Box $position='absolute'>
            <Text fontSize='0.9rem' color={color.primary1000} fontFamily={fontFamily.accent}>{innerValue}</Text>
        </Box>
        {value && <Text fontSize='0.9rem' fontFamily={fontFamily.accent}>{value}</Text>}
    </Box>
}

export default memo(Bread);