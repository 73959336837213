export const calcProperDate = () => {
  const now = new Date();
  let minDate = new Date(2023, 12 - 1, 1);
  const maxDate = new Date(2024, 3 - 1, 31);

  // 서비스 개시일 이후에는, 오늘부터 예약가능일이 된다.
  minDate = minDate.getTime() < now.getTime() ? now : minDate;

  // 오늘의 시각이 7시이후라면 내일 밤 이용권이 되므로 하루를 늘려준다.
  minDate.getHours() >= 7 ? minDate.setDate(minDate.getDate() + 1) : minDate;

  return { minDate, maxDate };
};
