import { memo } from 'react';
import styled from 'styled-components';
import { Icon, StyledIconProps } from '../icons/makeStyledIcon';

type IconButtonPropsType = {
  icon: Icon;
  title?: string;
  onClick?: () => void;
} & StyledIconProps;

const StyledButton = styled.button`
  border: none;
  background: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const StyledTitle = styled.span<{ $color?: string }>`
  ${({ $color, theme }) =>
    $color ? `color: ${$color}` : `color: ${theme.colors.grey400}`};
  font-size: 10px;
`;

const IconButton = ({
  icon: Icon,
  title,
  onClick,
  ...iconProps
}: IconButtonPropsType) => {
  return (
    <StyledButton onClick={onClick}>
      <Icon {...iconProps} />
      {title && <StyledTitle $color={iconProps.color}>{title}</StyledTitle>}
    </StyledButton>
  );
};

export default memo(IconButton);
