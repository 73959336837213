/* eslint-disable react/display-name */
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

export type Icon = ({
  size,
  color,
  ...restProps
}: StyledIconProps) => JSX.Element;

export type StyledIconProps = {
  size: number;
  color?: string;
};

export type IconProps = {
  color: string;
  width: number;
  height: number;
};

export const makeStyledIcon = (Icon: (props: IconProps) => ReactNode) => {
  return ({ size, color = 'grey', ...restProps }: StyledIconProps) => {
    const iconColor = color;

    return <Icon width={size} height={size} color={iconColor} {...restProps} />;
  };
};
