/**
 * 입력으로 주어지는 두 시간의 차이를 절댓값 초(sec)로 반환합니다.
 * @param a
 * @param b
 * @returns
 */
export const getTimeDiffInSec = (a: Date, b: Date) => {
  return Math.abs(b.getTime() - a.getTime()) / 1000;
};

/**
 * 입력으로 주어지는 Date를 날짜로 하는 00시 00분을 반환합니다.
 * @param dateObject
 * @returns
 */
export const getDate00 = (dateObject: Date) => {
  const date = new Date(dateObject);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};
