import { Stack } from 'components/Stack';
import Lottie from 'lottie-react';
import { memo } from 'react';

import LoadingLottie from 'assets/images/lotties/loading-section.json';

const LoadingView = () => {
    return <Stack as={'article'} $alignItems='center' $justifyContent='center' $minHeight='calc(var(--vh, 1vh) * 100)'>
        <Lottie animationData={LoadingLottie} />
    </Stack>
}

export default memo(LoadingView);