import axios from 'axios';

const BASE_URL = 'https://api.cagong24.com';
// const BASE_URL = 'http://localhost:8000';

export const publicAxios = axios.create({
  baseURL: BASE_URL,
});

export const authAxios = axios.create({
  baseURL: BASE_URL,
});
