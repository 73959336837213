import LoadingView from 'components/LoadingView';
import { useResizeHandler } from 'hooks/useResizeHandler';
import { useStepAuthenticateManager } from 'hooks/useStepManager';
import { useStoreLifeManager } from 'hooks/useStoreLifeManager';
import { Suspense, lazy } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router';
import Bread from 'screens/Bread';
import My from 'screens/My';
import styled from 'styled-components';

const AuthScreen = lazy(() => import('screens/Auth'));
const BookingResultScreen = lazy(() => import('screens/BookingResult'));
const Landing = lazy(() => import('screens/Landing'));
const PayScreen = lazy(() => import('screens/Pay'));
const Reservation = lazy(() => import('screens/Reservation'));

function App() {
  useResizeHandler();
  useStepAuthenticateManager();
  useStoreLifeManager();

  return (
    <Main className='main'>
      <Suspense fallback={<LoadingView />}>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/book' element={<Reservation />} />
          <Route path='/auth' element={<AuthScreen />} />
          <Route path='/my' element={<My />} />
          <Route path='/bread' element={<Bread />} />
          <Route path='/pay' element={<PayScreen />} />
          <Route path='/result' element={<BookingResultScreen />} />
        </Routes>
      </Suspense>
      <Toaster reverseOrder={false} />
    </Main>
  );
}

const Main = styled.main`
  background-color: white;
  min-height: calc(var(--vh, 1vh) * 100);
  max-width: var(--app-max-width);
  margin-left: auto;
  margin-right: auto;

  overflow-x: hidden;
  position: relative;
`;

export default App;
